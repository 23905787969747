import { Component, OnInit, Input } from '@angular/core';
import { Player } from 'src/app/models/player.model';

const SHIP_ICON_NORMAL: string = '/assets/ship_icon.png';
const SHIP_ICON_GONE: string = '/assets/ship_icon_gone.png';
const SHIP_ICON_DAMAGED: string = '/assets/ship_icon_destroyed.png';

@Component({
  selector: 'app-player-card',
  templateUrl: './player-card.component.html',
  styleUrls: ['./player-card.component.scss']
})
export class PlayerCardComponent implements OnInit {

  private _playerData: Player = {
    playerName: 'Test Player',
    isReady: false,
    isWaiting: false,
    isMining: false,
    inOrbit: false,
    isLaunching: false,
    commDamage: 0,
    shieldDamange: 0,
    electricalDamage: 0,
    launchDamage: 0,
    heatDamage: 0
  };

  @Input()
  public set playerData(data: Player) {
    this._playerData = data;
  }

  public get playerData(): Player {
    return this._playerData;
  }

  public get isShipLost(): boolean {
    let verdict: boolean = false;


    return this.playerData.commDamage > 1 ||
      this.playerData.shieldDamange > 1 ||
      this.playerData.electricalDamage > 1 ||
      this.playerData.launchDamage > 1 ||
      this.playerData.heatDamage > 1;
  }

  public get shipIconSrc(): string {
    if (this.playerData.inOrbit || this.playerData.isLaunching) {
      return SHIP_ICON_GONE;
    } else if (this.isShipLost) {
      return SHIP_ICON_DAMAGED;
    } else {
      return SHIP_ICON_NORMAL;
    }
  }

  //Action Overrides
  @Input()
  isDeploying: boolean = false;

  @Input()
  resolvingTurn: boolean = false;


  constructor() { }

  ngOnInit() {
  }

}
