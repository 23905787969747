import { Component, OnInit } from '@angular/core';
import { ColyseusService } from './colyseus.service';
import { Room } from 'colyseus.js';
import { Observable, interval } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  //Game States
  CONNECT_TO_SERVER: string = "connect_to_server";
  WAITING_FOR_PLAYERS: string = "waiting_for_players";
  RESOLVING_TURN: string = "resolving_turn";
  WAITING_FOR_INPUT: string = "waiting_for_input";
  ROUND_OVER: string = 'end_of_round';

  //Host States
  HOST_START_SEQUENCE: string = "host_start_sequence";
  HOST_WAIT_INPUT: string = "host_waiting_for_input";
  HOST_ROUND_END: string = "host_end_round";

  GAME: string = "in_game";
  GAME_OVER: string = "game_over";

  TURN_COUNTER: number = 60;
  NEW_ROUND_COUNTER: number = 120;

  public room: Room;

  public timerValue: number = 0;
  private timer: any;
  private timerSub: any;

  title = 'web-host-standin';

  public gameState: string = this.CONNECT_TO_SERVER;
  public hostState: string;

  constructor(private gameService: ColyseusService) {

  }

  public ngOnInit() {
    this.gameService.connect().then((room: Room) => {
      this.roomJoined(room);
    });
  }

  roomJoined(room: Room) {
    this.room = room;


    room.onStateChange((state) => {
      const oldState: string = this.gameState;
      const newState: string = this.room.state.gameState;
      console.log("OLD STATE: " + this.gameState + " NEW STATE: " + this.room.state.gameState)
      if (this.gameState === this.WAITING_FOR_PLAYERS && this.room.state.gameState === this.WAITING_FOR_INPUT) {
        //When goign from Wiating for players to waiting for input... The Game has started
        this.startingSequence();
      } else if (this.gameState === this.RESOLVING_TURN && this.room.state.gameState === this.WAITING_FOR_INPUT) {
        this.startTurnWait();

      } else if (this.hostState !== this.HOST_ROUND_END && this.room.state.gameState === this.ROUND_OVER) {
        this.hostState = this.HOST_ROUND_END;
        //this.startTimer(this.NEW_ROUND_COUNTER).subscribe(() => {
        //SEnd New Round Start To Server
        // });
      } else if (oldState === this.ROUND_OVER && newState === this.WAITING_FOR_INPUT) {
        this.hostState = this.HOST_WAIT_INPUT;
      }
      this.gameState = this.room.state.gameState;

    });
  }

  //TURNS
  private startTurnWait() {
    this.hostState = this.HOST_WAIT_INPUT;
    this.startTimer(this.TURN_COUNTER);
  }


  //TIMER MOVE TO SERVICE?
  private startTimer(time: number): Observable<string> {
    // if (this.timerSub) {
    //   this.timerSub.unsubscribe();
    // }
    this.timerValue = time;
    if (!this.timer) {
      this.timer = interval(1000);
    }


    const timerObservable: Observable<string> = new Observable((observer) => {
      this.timerSub = this.timer.subscribe(() => {
        this.timerValue--;
        if (this.timerValue < 1) {
          observer.next("complete")
          observer.complete()
          // this.timerSub.unsubscribe();
        }
      });

    });
    return timerObservable;
  }

  private startingSequence() {
    this.startTimer(2).subscribe(() => {
      console.log("TIMER COMPLETE");
      this.hostState = this.HOST_WAIT_INPUT;
      this.startTurnWait();
    });
    this.hostState = this.HOST_START_SEQUENCE;
  }

}
