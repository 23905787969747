import { Injectable } from '@angular/core';
import { Client, Room } from 'colyseus.js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColyseusService {

  private colyseusClient: Client;

  endpoint = (window.location.hostname.indexOf("herokuapp") === -1)
    ? "wss://space-cutters-dev.herokuapp.com" // development (local)
    : `${window.location.protocol.replace("http", "ws")}//${window.location.hostname}` // production (remote)

  constructor() {
    this.colyseusClient = new Client(this.endpoint);
  }

  async connect(options: Object = null): Promise<Room> {
    return await this.colyseusClient.create('spacecutter', { 'client_type': 'cutter_host' });

  }
  async listRooms() {
    this.colyseusClient.getAvailableRooms("spacecutter").then(rooms => {
      rooms.forEach((room) => {
        console.log(room.roomId);
        console.log(room.clients);
        console.log(room.maxClients);
        console.log(room.metadata);
      });
    }).catch(e => {
      console.error(e);
    });
  }
}
